.footer-admin {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  padding: 0 25px;

  @media only screen and (max-width: 590px) {
    flex-direction: column;
    align-items: center;

    .dot-btn-wrapper {
      padding-bottom: 0;
    }
  }

  .wrap_phone {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .phone {
      color: #6c757d;
      font-size: 18px;
      font-weight: 700

    }
    svg {
      width: 20px;
      height: 20px;
      color: #545cd8;
    }
  }
  
  .wrap_secondary {
    display: flex;
    flex-direction: column;
    align-items: center;

    .policy-container {
      margin: 0 15px;

      a {
        color: #545cd8; color: #545cd8;
        font-size: 16px;
      }

      @media only screen and (max-width: 590px) {
        margin-bottom: 10px;
      }
    }

    .text-secondary {
      color: #6c757d !important;

    }
  }

  .wrap_social-elements {
    display: flex;
    

    .social-elements {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 15px;


      .social-elements-pack {
        margin: 5px;
        display: flex;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #FCFCFC;
        line-height: 40px;
        text-align: center;
        color: #545cd8;
        text-decoration: none;
        font-size: 23px;
        transition: all .1s linear;

        & + a {
          margin-left: 10px;
        }

        &:hover {
          background: #545cd8;
          color: #FCFCFC;
        }
  
        &.pay-logo {
          width: 50px;
          height: 40px;
        }
        &.pay-logo:hover {
          background: #fff;
          color: #000;
        }

        @media only screen and (max-width: 590px) {
          & + a {
            margin-left: 10px;
          }
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

