.header-logo {
  display: flex;
  margin: 0 15px;
  svg {
    height: 40px;
    width: 225px;
    @media only screen and (max-width: 590px) {
      width: 25vw;
    }
  }
}