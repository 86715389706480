.burger-container {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    width: 30px;
    height: 2px;
    display: block;
    background-color: #343a40;
    transition: .5s;
  }

  .burger {
    margin: 3px 0;
  }

  .cross {
    transform: rotate(45deg);

    &:first-child {
      transform-origin: 13px 2px 0;
    }

    &:last-child {
      transform: rotate(-45deg);
    }

  }

}

.showed {

  .second-burger {
    height: 0;
    margin: 0;
  }
}
