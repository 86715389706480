.footer-common {
  height: 60px;
  background-color: #0d1229;
  border-top: 2px solid #788686;
}

.footer-client{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: right;

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
  @media only screen and (max-width: 590px) {
    flex-direction: column;
    align-items: center;
    height: unset;
    padding: 15px 0;
  }

  .policy-container {
    display: flex;
    align-items: center;
    margin: 0 15px;
    a {
      color: #52a4a4;
    }

    @media only screen and (max-width: 590px) {
      margin-bottom: 10px;
    }
  }

  .social-elements {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 15px;

    .social-elements-pack{
      margin: 5px;
      display: flex;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #FCFCFC;
      line-height: 40px;
      text-align: center;
      color: #52A4A4;
      text-decoration: none;
      font-size: 23px;
      transition: all .1s linear;

      & + a {
        margin-left: 10px;
      }

      &:hover {
        background: #52A4A4;
        color: #FCFCFC;
      }

      @media only screen and (max-width: 590px) {
        & + a {
          margin-left: 10px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

