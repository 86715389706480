.header-admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: 100;

  @media only screen and (min-width: 769px) and (max-width: 1249px) {
    .burger-container {
      display: none;
    }
  }

  .header-admin-wrapper {
    display: flex;
    height: inherit;
    padding: 0 25px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 2px solid #788686;

    @media only screen and (max-width: 400px) {
      padding: 0 20px;
    }
  }

  .user-container {
    display: flex;

    @media only screen and (max-width: 768px) {
      position: absolute;
      width: 90%;
      background-color: #fff;
      top: -80px;
      justify-content: flex-end;
      box-shadow: 0 0.46875rem  2.1875rem   rgba(8, 10, 37, 0.03),
                  0 0.9375rem   1.40625rem  rgba(8, 10, 37, 0.03),
                  0 0.25rem     0.53125rem  rgba(8, 10, 37, 0.05),
                  0 0.125rem    0.1875rem   rgba(8, 10, 37, 0.03);
      border-radius: 40px;
      padding: 0 20px;
      z-index: -1;
      transition: 0.75s;


      .controlPanel-lng-switch-container {
        margin: 15px;
      }
    }
  }

  .header-logo {
    svg {
      width: 325px;

      @media only screen and (max-width: 850px) {
        width: 255px;
      }

      @media only screen and (max-width: 610px) {
        width: 185px;
        height: 35px;
      }
    }
  }


  .active-user-block-tab-and-mobile {
    top: 80px;
  }


}