.footer-client{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: right;

  @media only screen and (max-width: 768px) {
    margin-top: 0;
    justify-content: center;
  }
  @media only screen and (max-width: 590px) {
    flex-direction: column;
    align-items: center;

  }

  .left-bar {
    flex-grow: 1;
    display: flex;
  }

  .contacts {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    .link {
      color: #ffffff;
      font-size: 0.75rem;
      span {
        margin-left: 5px;
      }
    }
  }

  .social-elements {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    .social-elements-pack{
      margin: 5px;
    }

    a {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #FCFCFC;
      line-height: 40px;
      text-align: center;
      color: #52A4A4;
      text-decoration: none;
      font-size: 23px;
      transition: all .1s linear;

      & + a {
        margin-left: 10px;
      }

      &:hover {
        background: #52A4A4;
        color: #FCFCFC;
      }

      @media only screen and (max-width: 590px) {
        & + a {
          margin-left: 10px;
        }
      }
    }
  }
}
