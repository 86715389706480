.client-lng-switch-container {
  display: flex;
  align-items: center;
  margin: 20px;

  .lng-btn {
    padding: 4px 6px;
    font-size: 16px;
    border-radius: 50%;
    border: 2px solid #6e6e80;
    color: #000;
  }

  .active-lng-btn {
    background-color: #52A4A4;
    color: #fff;
  }


  .lng-slash {
    margin: 0 5px;
  }

}

.controlPanel-lng-switch-container {
  display: flex;
  align-items: center;
  margin: 20px;

  .lng-btn {
    padding: 4px 6px;
    font-size: 16px;
    border-radius: 50%;
    border: 2px solid #545cd8;
    color: #000;
    background-color: unset;
  }

  .active-lng-btn {
    background-color: #545cd8;
    color: #fff;
  }


  .lng-slash {
    margin: 0 5px;
  }
}

.button-language {
  width: 50px;
  height: 40px;
  margin: 0 auto;
  background: #545cd8;
  border: none !important;
}

.bg-none {
  width: 50px;
  height: 40px;
  margin: 0 auto;
  border: none !important;
  background: none;
  color: #000;
}