.header-client{
  background-color: #f5f5fb;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #788686;
}

.icons-wrapper {
  display: flex;
  align-items: center;
  margin: 0 15px;

  .icon {
    width: 30px;
    height: 30px;

  }

  svg{
    width: 40px;
  }

  .icon-auth-user{
    svg{

    }
  }
}


