.tree-dots-wrapper {
  min-width: 45px;
  position: relative;
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
}

.three-dots-menu-container {
  position: absolute;
  top: 50%;
  left: 50%;

  padding: 5px;
  background: #545cd8;
  border-radius: 5px;
  transform: translate(-50%, -50%);



  .one-dot {
    width: 5px;
    height: 5px;
    border-radius: 25px;
    background: #fff;
    margin: 3px;
    display: block;
    transition: .2s;
  }

}

.dots-active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .one-dot {
    width: 25px;
    height: 4px;
    transition: .2s;

    transform: rotate(45deg);

    &:first-child {
      transform-origin: 6px 4px 0;
    }

    &:last-child {
      transform: rotate(-45deg);
      transform-origin: 7px 0 0;
    }
  }

  .second-dont {
    transition: height .3s;
    height: 0;
    margin: 0;
    width: 0;
    opacity: 0;
  }
}

.dots-inactive {
  .second-dont {
    transition-delay: .1s;
  }
}